import Vue from "vue";
import Router from "vue-router";
// import HomeView from "../views/HomeView.vue";

Vue.use(Router);

export default new Router({
    routes: [{
            path: "/",
            name: "home",
            // component: HomeView,
            redirect: "/home/homePage",
            component: (resolve) => require(["@/views/HomeView.vue"], resolve),
            children: [{
                    path: "/home/homePage",
                    name: "首页",
                    component: () =>
                        import ("@/views/homePage.vue"),
                },
                {
                    path: "/home/ProductCenter",
                    name: "产品中心",
                    iconClass: "fa fa-list",
                    component: () =>
                        import ("@/views/ProductCenter.vue"),
                },
                {
                    path: "/home/ClassicCases",
                    name: "经典案例",
                    iconClass: "fa fa-list",
                    component: () =>
                        import ("@/views/ClassicCases.vue"),
                },
                {
                    path: "/home/CompanyProfile",
                    name: "公司简介",
                    iconClass: "fa fa-list",
                    component: () =>
                        import ("@/views/CompanyProfile.vue"),
                },
            ],
        },
        {
            path: "*",
            component: (resolve) => require(["@/views/NotFound.vue"], resolve),
        },
    ],
    mode: "history",
});

// const routes = [{
//         path: "/",
//         name: "home",
//         component: HomeView,
//         children: [{
//                 path: "/home/homePage",
//                 name: "首页",
//                 component: () =>
//                     import ("@/views/HomeView.vue"),
//             },
//             {
//                 path: "/home/ProductCenter",
//                 name: "产品中心",
//                 iconClass: "fa fa-list",
//                 component: () =>
//                     import ("@/views/ProductCenter.vue"),
//             },
//             {
//                 path: "/home/ClassicCases",
//                 name: "经典案例",
//                 iconClass: "fa fa-list",
//                 component: () =>
//                     import ("@/views/ClassicCases.vue"),
//             },
//             {
//                 path: "/home/CompanyProfile",
//                 name: "公司简介",
//                 iconClass: "fa fa-list",
//                 component: () =>
//                     import ("@/views/CompanyProfile.vue"),
//             },
//         ],
//     },
//     {
//         path: "/about",
//         name: "about",
//         // route level code-splitting
//         // this generates a separate chunk (about.[hash].js) for this route
//         // which is lazy-loaded when the route is visited.
//         component: () =>
//             import ( /* webpackChunkName: "about" */ "../views/AboutView.vue"),
//     },
//     {
//         path: "*",
//         component: (resolve) => require(["@/views/NotFound.vue"], resolve),
//     },
// ];

// const router = new VueRouter({
//     routes,
// });

// export default router;